<template>
    <div>
        <!-- <el-menu
            router
            :default-active="activeIndex"
            mode="horizontal"
            @select="handleSelect">
        <el-menu-item :index="item.path" v-for="item of urlList" :key="item.id">{{ item.name }}</el-menu-item>
        </el-menu> -->
        <router-view />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                //   activeIndex:'',
                //   urlList:[
                //     {id:0,name:'日常巡检',path:'/daily/1'},
                //     {id:1,name:'定期检查',path:'/daily/2'},
                //     {id:2,name:'特殊检测',path:'/daily/3'},
                //     {id:3,name:'荷载试验',path:'/daily/4'},
                //     {id:4,name:'桥梁养护',path:'/daily/5'},
                // ],
            }
        },
        mounted () {
            var path = sessionStorage.getItem('name3')
            if(path ==  '日常巡检'){
                  this.activeIndex ='/daily/1'
            }
            if(path ==  '定期检查'){
                  this.activeIndex ='/daily/2'
            }
            if(path ==  '特殊检测'){
                  this.activeIndex ='/daily/3'
            }
            if(path ==  '荷载试验'){
                  this.activeIndex ='/daily/4'
            }
            if(path ==  '桥梁养护'){
                  this.activeIndex ='/daily/5'
            }
            console.log('path', path)
            this.$store.commit('getName3',path);

        },
        methods: {

            handleSelect (index) {
                console.log(index)
                this.activeIndex = index;
            }
        },
    }
</script>



<style lang="scss" scoped>

</style>